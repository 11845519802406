<template>
	<main>
		<h1>Users</h1>
		<ResponsiveTable :headers="['Name', 'Roles', 'Classes', '']" :rows="users" />

		<BaseButton type="primary" :href="{name: 'AdminUsersCreate'}">
			Add user
		</BaseButton>
	</main>
</template>

<script>
	import Backend from '../../inc/backend';
	import {roleGuard} from '../../inc/auth';
	import BaseButton from '../../components/BaseButton.vue';
	import ResponsiveTable from '../../components/ResponsiveTable';

	export default {
		...roleGuard('admin'),
		components: {
			BaseButton,
			ResponsiveTable
		},
		data() {
			return {
				users: []
			};
		},
		created() {
			const params = {};

			if(this.$route.query.class) {
				params.class = this.$route.query.class;
				params.role = 'student';
			}
			else {
				params.all = 1;
			}

			Backend.get('users', {params}).then(res => {
				this.users = res.data.map(user => [
					{label: user.firstName + ' ' + user.lastName},
					{label: user.roles.join(', ')},
					{label: user.classes.map(c => `${c.name} (${c.code})`).join(', ')},
					{label: 'Edit', href: {name: 'AdminUsersEdit', params: {id: user.id}}}
				]);
			});
		}
	};
</script>

<style lang="scss" scoped>
	h2 {
		margin-top: $default_padding * 2;
	}
</style>